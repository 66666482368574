<template>
  <div>
    <el-dialog
      :modelValue="props.show"
      width="450px"
      destroy-on-close
      style="padding: 0"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <img class="close" @click="handleClose" src="https://static.ppkao.com/www/images/icon-close.png"/>

      <div class="wrap">
        <div class="box">
          <div class="head">
            <img src="https://static.ppkao.com/www/images/bg-tip.png"/>
            <p class="discount_time">优惠倒计时：
              <span class="swing animated"> {{ countDown }} </span>
            </p>
          </div>
          <div class="tips" >
            <p class="p1">{{ price.GroupBuyRemark }}</p>
            <p :class="{ 'show-discount': showDiscount, 'price': true }">
              <span class="original-price">
                <span v-if="showDiscount">原价</span>
                <span v-if="price.OrigPrice">{{ price.OrigPrice }}元</span>
              </span>
              <span class="discount-price">{{ price.CurrentPrice }}元</span>
            </p>
            <p class="p3">{{ price.Remarks }}</p>
          </div>
          <div class="body">
            <div class="qrcode-box" :class="{'show-desc': unfoldDesc}">
              <div class="qrcode-img" >
                <el-skeleton style="width: 200px" :loading="loading" animated >
                  <template #template>
                    <el-skeleton-item variant="image" style="width: 200px; height: 200px" />
                  </template>
                  <template #default>
                    <img class="icon" src="https://static.ppkao.com/www/images/icon-wechatpay.png">
                  </template>
                </el-skeleton>
                 <img class="qrcode"
                      :src="price.payUrl"
                      :style="{opacity: loading ? '0': '1'}"
                      @load="loading = false">
                <div v-if="timeout" class="timeout-box" @click="refreshQrcode">
                  <RefreshSvg />
                </div>
              </div>
              <div class="footer">
                <img src="https://static.ppkao.com/www/images/icon-scan.png"/>
                <p>
                  打开微信扫一扫<br>
                  扫描二维码付款
                </p>
              </div>
            </div>
            <div class="desc-table" :class="{'hide-table': !unfoldDesc}" >
              <div class="table-box">
                <p>1. 搜题包次数扣减规则：</p>
                <table>
                  <thead>
                  <tr>
                    <th rowspan="2">功能(查看答案)</th>
                    <th colspan="2">扣减规则</th>
                  </tr>
                  <tr>
                    <th>基础费</th>
                    <th>加收费<br>（AI功能）</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>文字搜题</td>
                      <td>1次/题</td>
                      <td>0/每次</td>
                    </tr>
                    <tr>
                      <td>语音搜题</td>
                      <td>1次/题</td>
                      <td>0/每次</td>
                    </tr>
                    <tr>
                      <td>拍照识别</td>
                      <td>1次/题</td>
                      <td>0/每次</td>
                    </tr>
                  </tbody>
                </table>
                <p>2.充值条款：搜题次数单价0.2元/次，本搜题包为优惠套餐，不退款，过期后不进行补发或延长有效期</p>
              </div>
            </div>
            <div class="description" @click="unfoldDesc = !unfoldDesc">
              <div class="circle">
                <div class="triangle" :style="{transform: unfoldDesc ? 'rotate(180deg)' : 'rotate(0deg)'}"></div>
              </div>
              <span>权益说明</span>
            </div>
          </div>
        </div>

        <div class="btn">
            <button v-wave @click="() => queryOrder()" :class="{'loading': querying}">
              <img src="https://static.ppkao.com/www/images/icon-refresh.png">
              <span>点击查询支付结果</span>
            </button>
          <el-popover
            trigger="click"
            placement="top"
            :effect="effect"
          >
            <template #reference>
              <span class="kf-btn" >已付款但还是不能看答案？</span>
            </template>
            <div class="kf-box">
              <img src="https://static.ppkao.com/public/wxqrcode/pptiku.jpg" alt="联系客服"/>
              <span>微信扫一扫后联系客服</span>
            </div>
          </el-popover>

        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { getSearchPack, getActivityDate, placeOrder, getOrderID } from '~/api';
import RefreshSvg from '@/assets/svgs/refresh.svg'

const modalStore = useModalStore()
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false
  }
});
const effect = computed(() => useNuxtApp().$darkMode.isDarkMode.value ? 'dark' : 'light');
let timer; // 倒计时
const loading = ref(true); // 购买二维码图片是否加载中
const querying = ref(false); // 查询订单状态
const countDown = ref(''); // 页面的定时器
const showDiscount = ref(false); // 显示折扣价的控制动画
const unfoldDesc = ref(false); // 控制权益列表展开显示
const timeout = ref(false);
let price = reactive({});
const emit = defineEmits();

const handleClose = () => {
  console.log('[点击关闭购买]')
  clearInterval(timer);
  modalStore.setModal('pay', false);
  showDiscount.value = false;
  loading.value = true;
  countDown.value = null;
  price = reactive({});
  timeout.value = false;
  setTimeout(()=>{
    modalStore.setModal('scan', 'https://static.ppkao.com/assets/qrcode.png')
  }, 300)
};

// 监听 prop 的变化，并更新本地状态
watch(() => props.show, (newValue) => {
  if (newValue) {
    getActivityDate(countDown);
    // 获取搜题包价格
    refreshQrcode();
  }
});
const refreshQrcode = () => {
  timeout.value = false;
  loading.value = true;
  getSearchPack()
    .then(res => {
      let count = 0;
      price =  res.pList[0];
      price.GroupBuyRemark = price.GroupBuyRemark.replace(new RegExp(`${price.CurrentPrice}元`, 'g'), '');

      placeOrder({
        PID: price.ID,
        PaymentID: 17
      }).then(res => {
        const { payUrl, OrderID } = res;
        Object.assign(price,{ payUrl, OrderID});
        timer = setInterval(() => {
          if (count >= 60) { // 1分钟查询一次
            clearInterval(timer);// 查询了两分钟后没有结果就关闭查询
            return;
          }

          queryOrder(OrderID);
          count++;
        }, 2000)
      })
      setTimeout(() => {
        showDiscount.value = true;
      }, 750);  // 0.75秒延迟
    }).catch(err => {
      timeout.value = true;
      loading.value = false;
      console.error('[捕获请求超时]:', err)
  });
}
const queryOrder = (OrderID) => {
  if (!OrderID) {
    clearInterval(timer);
    if (querying.value) return;
    querying.value = true;
  }
  getOrderID({ OrderID: OrderID || price.OrderID })
    .then(res => {
      if (res.S === '1' && res.OrderModel[0].Status === '1') {
         // 支付成功
        ElMessage.success('支付成功~');
        handleClose();
        emit('success');
      } else if(!OrderID){
        ElMessage({
          type: 'warning',
          message: '未查询到购买成功的结果,如有问题请点击联系客服',
          grouping: true
        });
      }
    }).finally(() =>  querying.value = false);
}
</script>
<style>
:root {
  --pay-btn-border: #ff5655;
  --pay-btn-bg: #fff3f3;
  --pay-btn-color: #ff5655;
  --orig-color: #999;
  --p1-color: #333333;
}
html.dark {
  --pay-btn-border: var(--primary-text-color);
  --pay-btn-bg: var(--black-btn-border);
  --pay-btn-color: var(--btn-search-text-color);
  --orig-color: #d9d9d9;
  --p1-color: #a9a9a9;
}
.el-message {
  z-index: 3333 !important;
}
</style>
<style scoped lang="scss">
:deep(.el-dialog__header) {
  display: none;
}
.close {
  position: absolute;
  right: -50px;
  width: 38px;
  cursor: pointer;
}
.wrap {
  background-color: var(--card-bg-color);
}
.box {
  overflow: hidden;
  padding: 2px;
  .head {
    background-color: rgb(255,83,83); /* 先设定背景颜色 */
    background-image: url("https://static.ppkao.com/www/images/bg-pay.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 100px;
    background-size: 100% 100%;
    position: relative;
    img {
      position: absolute;
      top: 10px;
      left: 2px;
    }
    .discount_time {
      @include font(#fff, 14px);
      display: flex;
      align-items: flex-end;
      position: absolute;
      left: 10px;
      bottom: 10px;
      span {
        display: block;
        @include font(#f5e507, 24px);
        text-shadow: 1px 1px 8px #f54340;
      }
    }

  }
  .tips {
    @include flex-col-center;
    padding: 15px 0;
    .p1 {
      @include font(var(--p1-color), 16px);
    }
    .price {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center; // 保持内容居中
      position: relative;

      .original-price {
        @include font(var(--title-color), 20px, 600);
        transition: transform 0.4s ease, opacity 0.4s ease; // 延长动画时间
        display: inline-block;
        opacity: 1;

      }

      .discount-price {
        @include font(var(--title-color), 24px, 600);
        transform: scale(0.1); // 初始缩小
        opacity: 0;
        transition: transform 0.4s ease, opacity 0.3s ease;
        transition-delay: 0.3s; /* 整体延迟 */
        display: inline-block;
        position: absolute;
      }
    }

    .show-discount {
      .original-price {
        @include font(var(--orig-color), 14px);
        transform: translate(-84px, 3px) scale(0.8); // 左滑并缩小
        opacity: 0.6;
        span:last-child {
          text-decoration: line-through;
        }
      }
      .discount-price {
        opacity: 1;
        transform: scale(1); // 居中并放大
      }
    }

    .p3 {
      @include font(#a5a5a5, 14px);
    }
  }
  .body {
    border-radius: 10px;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    @include flex-y-center;
    .description {
      position: absolute;
      right: 0;
      @include flex-y-center;
      cursor: pointer;
      .circle {
        padding: 6px;
        background-color: rgba(0,0,0,0.05);
        border-radius: 50%;
        @include flex-center;
        animation: moveLeftRight 1.5s infinite alternate;
      }

      .triangle {
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 7px solid #ff5655;
      }
      span {
        writing-mode: vertical-rl;
        @include font(#ff5655, 14px, 600);
        padding: 0 6px;
      }
    }

    .qrcode-box {
      transition: all .4s;
      background: var(--bg-color);
      border: 1px solid var(--border-2-color);
      box-shadow: 0 2px 6px 0 rgba(228, 215, 215, 0);
      font-size: 14px;
      .qrcode-img {
        width: 200px;
        height: 200px;
        transition: all .4s;
        position: relative;
        @include flex-center;
        .qrcode {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
        }
        .icon {
          width: 32px;
          position: absolute;
          z-index: 2;
          transition: all .4s;
        }
      }
      .footer {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 6px;
        justify-content: center;
        img {
          width: 30px;
          transition: all .4s;
        }
        p {
          @include font(var(--primary-text-color), 14px, 500);
          line-height: 18px;
          margin-left: 12px;
          transition: all .4s;
        }
      }
    }
  }
}
.btn {
  padding: 10px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    border: 1px solid var(--pay-btn-border);
    font-weight: 600;
    @include btn(100%, 50px, var(--pay-btn-bg), 5px, 16px, var(--pay-btn-color));
    span {
      margin-left: 10px;
    }
  }
  .loading {
    cursor: not-allowed;
    img {
      animation: rotate 0.6s linear infinite;
    }
  }
  .kf-btn {
    cursor: pointer;
    @include font(var(--primary-text-color), 14px);
    text-decoration-line: underline;
    margin-top: 10px;
  }
}

@keyframes swing {
   20% {
     transform: rotate(4deg);
   }
   40% {
     transform: rotate(-2deg);
   }
   60% {
     transform: rotate(0deg);
   }
   80% {
     transform: rotate(-2deg);
   }
   to {
     transform: rotate(0deg);
   }
 }

.animated {
  transform-origin: top center;
  animation: swing 1s infinite;
}
@keyframes moveLeftRight {
  0% {
    transform: translateX(4px); /* 右移到圆心右侧 */
  }
  100% {
    transform: translateX(-2px); /* 左移到圆心左侧 */
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.show-desc {
  .qrcode-img {
    width: 150px !important;
    height: 150px !important;
  }
  .icon {
    width: 26px !important;
  }
  .footer {
    img {
      width: 26px !important;
    }
    p {
      font-size: 12px !important;
      margin-left: 8px !important;
    }
  }
}
.hide-table {
  max-width: 0 !important;
}
.desc-table {
  align-self: flex-start;
  max-width: 50%;
  transition: max-width .3s;
  overflow: hidden;
  max-height: 250px;
  .table-box {
    display: flex;
    flex-direction: column;
    padding: 0 14px;
    text-align: justify;
    @include font(#657180, 12px);
    line-height: 16px;

    p {
      padding: 6px 0;
      font-size: 12px;
    }
    p:nth-child(1) {
      padding-top: 0;
    }
    table {
      width: 100%;
      border: 1px solid #C7C7C7;
      border-top: 0;
      border-right: 0;
      th {
        vertical-align: middle;
        background-color: #9AA7B8;
        @include font(#FFFFFF, 12px, 600);
        text-align: center;
        border-top: 1px solid #C7C7C7;
        border-right: 1px solid #C7C7C7;
        padding: 2px 0;
      }
      td {
        text-align: center;
        @include font(var(--primary-text-color), 12px);
        border-right: 1px solid #C7C7C7;
        border-bottom: 1px solid #C7C7C7;
        padding: 4px;
      }

    }
  }
}
.kf-box {
  @include flex-col-center;
  img {
    width: 120px;
  }
  span {
    text-align: center;
    margin-top: 6px;
    @include font(var(--title-color))
  }
}
.timeout-box {
  cursor: pointer;
  @include flex-center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  backdrop-filter: blur(4px);
  -webkit-filter: blur(.6);
  filter: blur(.6);
  text-align: center;
  @include font(var(--primary-text-color), 16px, 600);
  background: hsla(0, 0%, 100%, .6);
  -webkit-backdrop-filter: blur(4px);
  span {
    line-height: 24px;
    @include font(#050505, 16px, 600);
  }
  svg {
    background-color: #fff;
    width: 2em;
    fill: #ff5655;
    border-radius: 50%;
    overflow: hidden;
  }
}

</style>
